// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-en-blog-js": () => import("./../../../src/pages/en/blog.js" /* webpackChunkName: "component---src-pages-en-blog-js" */),
  "component---src-pages-en-index-js": () => import("./../../../src/pages/en/index.js" /* webpackChunkName: "component---src-pages-en-index-js" */),
  "component---src-pages-en-projects-js": () => import("./../../../src/pages/en/projects.js" /* webpackChunkName: "component---src-pages-en-projects-js" */),
  "component---src-pages-fa-blog-js": () => import("./../../../src/pages/fa/blog.js" /* webpackChunkName: "component---src-pages-fa-blog-js" */),
  "component---src-pages-fa-index-js": () => import("./../../../src/pages/fa/index.js" /* webpackChunkName: "component---src-pages-fa-index-js" */),
  "component---src-pages-fa-wiki-js": () => import("./../../../src/pages/fa/wiki.js" /* webpackChunkName: "component---src-pages-fa-wiki-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-en-post-js": () => import("./../../../src/templates/en_post.js" /* webpackChunkName: "component---src-templates-en-post-js" */),
  "component---src-templates-en-project-js": () => import("./../../../src/templates/en_project.js" /* webpackChunkName: "component---src-templates-en-project-js" */),
  "component---src-templates-fa-post-js": () => import("./../../../src/templates/fa_post.js" /* webpackChunkName: "component---src-templates-fa-post-js" */),
  "component---src-templates-fa-wiki-js": () => import("./../../../src/templates/fa_wiki.js" /* webpackChunkName: "component---src-templates-fa-wiki-js" */)
}

